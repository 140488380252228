<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Dodaj</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">
            <div v-show="this.selectedUnitTypeKey === 'education'">
                <div class="p-field p-col-12 p-md-6">
                    <label for="firstName">Imię ucznia</label>
                    <InputText id="firstName" type="text" v-model="content.firstName"
                               placeholder="Imię ucznia"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="lastName">Nazwisko ucznia</label>
                    <InputText id="lastName" type="text" v-model="content.lastName"
                               placeholder="Nazwisko ucznia"/>
                </div>
            </div>
            <div v-show="this.selectedUnitTypeKey === 'social' || this.selectedUnitTypeKey === 'population'">
                <div class="p-field p-col-12 p-md-6">
                    <label for="firstName">Imię</label>
                    <InputText id="firstName" type="text" v-model="content.firstName"
                               placeholder="Imię"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="lastName">Nazwisko</label>
                    <InputText id="lastName" type="text" v-model="content.lastName"
                               placeholder="Nazwisko"/>
                </div>
            </div>
            <div class="p-field p-col-12">
                <label for="place">Miejscowość</label>
                <InputText id="place" type="text" v-model="content.place" placeholder="Wpisz miejscowość"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="street">Ulica</label>
                <InputText id="street" type="text" v-model="content.street" placeholder="Wpisz ulicę"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="zipCode">Kod pocztowy</label>
                <InputText id="zipCode" type="text" v-model="content.zipCode" placeholder="Wpisz kod pocztowy"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="buildingNumber">Numer budynku</label>
                <InputText id="buildingNumber" type="text" v-model="content.buildingNumber"
                           placeholder="Wpisz numer budynku"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="apartmentNumber">Numer mieszkania</label>
                <InputText id="apartmentNumber" type="text" v-model="content.apartmentNumber"
                           placeholder="Wpisz numer mieszkania"/>
            </div>
            <div class="p-field p-col-12 p-md-6" v-show="this.selectedUnitTypeKey === 'water'">
                <label for="waterConsumption">Zużycie wody [m<sup>3</sup>]</label>
                <InputText id="waterConsumption" type="number" min="0" step="1" v-model="content.waterConsumption"
                           placeholder="Wpisz zurzycie wody"/>
            </div>
            <div class="p-field p-col-12 p-md-6" v-show="residents">
                <label for="residents">Liczba mieszkańców </label>
                <InputText id="residents" type="number" min="0" step="1" v-model="content.residents"
                           placeholder="Podaj liczbę mieszkańców"/>
            </div>
            <div v-show="this.selectedUnitTypeKey === 'education'" v-for="(guardian, index) in content.guardians"
                 v-bind:key="index">
                <h4>Opiekun {{index+1}}</h4>
                <div class="p-field p-col-12 p-md-6">
                    <label :for="`guardian${index}firstName`">Imię</label>
                    <InputText :id="`guardian${index}firstName`" type="text" v-model="guardian.firstName"
                               placeholder="Imię"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label :for="`guardian${index}lastName`">Nazwisko</label>
                    <InputText :id="`guardian${index}lastName`" type="text" v-model="guardian.lastName"
                               placeholder="Nazwisko"/>
                </div>
                <div class="p-field p-col-12">
                    <label :for="`guardian${index}place`">Miejscowość</label>
                    <InputText :id="`guardian${index}place`" type="text" v-model="guardian.place"
                               placeholder="Wpisz miejscowość"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label :for="`guardian${index}street`">Ulica</label>
                    <InputText :id="`guardian${index}street`" type="text" v-model="guardian.street"
                               placeholder="Wpisz ulicę"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label :for="`guardian${index}zipCode`">Kod pocztowy</label>
                    <InputText :id="`guardian${index}zipCode`" type="text" v-model="guardian.zipCode"
                               placeholder="Wpisz kod pocztowy"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label :for="`guardian${index}buildingNumber`">Numer budynku</label>
                    <InputText :id="`guardian${index}buildingNumber`" type="text" v-model="guardian.buildingNumber"
                               placeholder="Wpisz numer budynku"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label :for="`guardian${index}apartmentNumber`">Numer mieszkania</label>
                    <InputText :d="`guardian${index}apartmentNumber`" type="text" v-model="guardian.apartmentNumber"
                               placeholder="Wpisz numer mieszkania"/>
                </div>
            </div>
        </div>
        <br/>
        <div class="p-field p-col-12 p-md-6 fa-pull-left"
             v-show="this.selectedUnitTypeKey === 'education' && this.content.type === 'child'">
            <Button type="button" class="p-button-success" label="Dodaj opiekuna" v-on:click="addGuardian"/>
            <Button v-show="this.showRemoveGuardian" type="button" class="p-button-danger" label="Usuń opiekuna"
                    v-on:click="removeGuardian"/>
        </div>
        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
            <Button type="button" label="Zapisz" v-on:click="click"/>
        </div>
    </div>
</template>

<script>
    import UnitDataService from "../../../services/unitsData.service";
    import {watch, ref} from "vue";

    export default {
        name: "AddEditForm",
        inject: ['unitTypes', 'global'],
        mode: '',
        selectedUnitTypeKey: '',
        showRemoveGuardian: false,
        includeResidents: [],
        residents: false,
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    place: "",
                    street: "",
                    zipCode: "",
                    buildingNumber: "",
                    apartmentNumber: "",
                    quarter: null,
                    year: null,
                    unit: null
                }
            },
        },
        data() {
            return {
                content: this.initialCntent,
                selectedPeroid: ref(this.global)
            };
        },
        methods: {
            click: function () {
                if (this.selectedUnitTypeKey === 'education') {
                    this.content.type = undefined;
                } else if (this.selectedUnitTypeKey === 'water') {
                    this.content.waterConsumption = parseInt(this.content.waterConsumption);
                }
                if (this.includeResidents.includes(this.selectedUnitTypeKey)) {
                    this.content.residents = parseInt(this.content.residents);
                }
                this.content.street =  this.content.street ? this.content.street : null;
                this.content.apartmentNumber = this.content.apartmentNumber ? this.content.apartmentNumber : null;
                if (this.mode === 'edit') {
                    UnitDataService.editForm(this.selectedUnitTypeKey, this.content, this.$route.params.formId).then(
                        () => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sukces',
                                detail: 'Poprawnie zapisano formularz',
                                life: 3000
                            });
                            this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/${this.$route.params.type}/${this.$route.params.unitId}/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log(this.content);
                        }
                    );
                } else {
                    this.content.unit = this.content.unit.toString();
                    UnitDataService.addForm(this.selectedUnitTypeKey, this.content).then(
                        () => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sukces',
                                detail: 'Poprawnie zapisano formularz',
                                life: 3000
                            });
                            this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/${this.$route.params.type}/${this.$route.params.unitId}/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log(this.content);
                        }
                    );
                }
            },
            goBack: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/${this.$route.params.type}/${this.$route.params.unitId}/lista`});
            },
            addGuardian: function () {
                this.content.guardians.push({
                    place: this.content.place,
                    street: this.content.street,
                    zipCode: this.content.zipCode,
                    buildingNumber: this.content.buildingNumber,
                    apartmentNumber: this.content.apartmentNumber,
                    firstName: "",
                    lastName: ""
                });
                this.showRemoveGuardian = true;
            },
            removeGuardian: function () {
                this.content.guardians.pop();
                this.showRemoveGuardian = this.content.guardians.length > 0 ? true : false;
            }
        },
        created() {
            this.selectedUnitTypeKey = this.unitTypes.find(o => o.name === this.$route.params.type).unitTypeKey;
            this.content.quarter = this.global.state.selectedPeroid.quarter;
            this.content.year = this.global.state.selectedPeroid.year;
            this.includeResidents = ['housing', 'government'];
            if (this.selectedUnitTypeKey === 'education') {
                console.log("education");
                this.content.firstName = "";
                this.content.lastName = "";
                this.content.guardians = [];
                this.content.type = this.content.type ? this.content.type : 'child';
            }  else if (this.selectedUnitTypeKey === 'social' || this.selectedUnitTypeKey === 'population') {
                console.log("social");
                this.content.firstName = "";
                this.content.lastName = "";
            } else if (this.includeResidents.includes(this.selectedUnitTypeKey)) {
                console.log("includeResidents");
                this.content.residents = null;
                this.residents = true;
            } else if (this.selectedUnitTypeKey === 'water') {
                console.log("water");
                this.content.waterConsumption = null;
            }
        },
        mounted() {
            this.mode = this.$route.params.formId ? 'edit' : 'add';
            watch(this.selectedPeroid, (val) => {
                if (this.mode === 'add') {
                    this.content.quarter = val.state.selectedPeroid.quarter;
                    this.content.year = val.state.selectedPeroid.year;
                }
            });
            if (this.mode === 'edit') {
                UnitDataService.getForm(this.selectedUnitTypeKey, this.$route.params.formId).then(
                    (response) => {
                        this.content = response.data;
                        this.content.id = undefined;
                        this.content.createdAt = undefined;
                        this.content.updatedAt = undefined;
                        if (this.content.guardians && this.content.guardians.length > 0) {
                            this.showRemoveGuardian = true;
                        }
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            } else {
                this.content.unit = parseInt(this.$route.params.unitId);
            }
        },
    };
</script>
