import api from './api';

const API_URL = `/units-data`;

class UnitDataService {

    getList(id, limit, page, selectedUnitTypeKey, filter) {
        return api.get(`${API_URL}/${selectedUnitTypeKey}`, {
                params: {
                    units: id,
                    limit: limit,
                    page: page,
                    quarter: filter.quarter,
                    year: filter.year
                }
            }
        );
    }

    getForm(type, id) {
        return api.get(`${API_URL}/${type}/${id}`
        );
    }

    addForm(type, content) {
        return api.post(`${API_URL}/${type}`, content);
    }

    editForm(type, content, id) {
        return api.patch(`${API_URL}/${type}/${id}`, content);
    }

    removeForm(id) {
        return api.delete(`${API_URL}/${id}`
        );
    }

    uploadFile(type, content) {
        return api.post(`${API_URL}/${type}/upload`, content);
    }

}

export default new UnitDataService();
